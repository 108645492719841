import {
  BehaviorSubject,
  Observable,
  Subject,
  finalize,
  map,
  take,
} from 'rxjs';
import { BingliAuthService, CredentialType } from '@mybingli/security-service';
import {
  User,
  UserInfo,
  UserPreferences,
  UserProfile,
} from '../account/data/account.model';
import {
  defaultUserInfo,
  defaultUserPreferences,
  placeholderUserPicPath,
} from '../account/data/account.constants';

import { ApiRequest } from 'src/app/shared/data/models/api-request';
import { ClientService } from 'src/app/shared/services/client.service';
import { GlobalLoaderService } from 'src/app/shared/services/global-loader.service';
import { HttpClient } from '@angular/common/http';
import { IApiResponse } from 'src/app/shared/data/models/api-response';
import { Injectable, computed, signal } from '@angular/core';
import { LanguageService } from 'src/app/shared/services/language.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import {
  fallbackLanguage,
  practitionerLanguages as practitionerLanguagesUppercased,
} from '@mybingli/language-picker';
import { isType } from '@shared/utils/helpers';
import { localStorageLanguageKey } from '@shared/data/constants';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private userInfoSubject = new BehaviorSubject<UserInfo>(defaultUserInfo);
  private userPreferencesSubject = new BehaviorSubject<UserPreferences>(
    defaultUserPreferences
  );
  private displayedProfilePicSubject = new BehaviorSubject<string>(
    placeholderUserPicPath
  );
  private editModeSubject = new BehaviorSubject<boolean>(false);
  private profilePicChangeSuccessSubject = new Subject<void>();

  public userInfo$: Observable<UserInfo> = this.userInfoSubject.asObservable();
  public userPreferences$: Observable<UserPreferences> =
    this.userPreferencesSubject.asObservable();
  public profilePic$: Observable<string> =
    this.displayedProfilePicSubject.asObservable();
  public profilePicChangeSuccess$: Observable<void> =
    this.profilePicChangeSuccessSubject.asObservable();
  public editMode$: Observable<boolean> = this.editModeSubject.asObservable();

  public allCredentialTypes: CredentialType[] = Object.values(
    CredentialType
  ).filter(isType<CredentialType>);

  private temporarilyDisabledLoginMethods = [
    CredentialType.GoogleTotp,
    CredentialType.Fido2,
    CredentialType.MicrosoftTotp,
    CredentialType.LastPassTotp,
  ];

  public registeredLoginMethods = signal<CredentialType[]>([]);
  public visibleRegisteredLoginMethods = computed(() =>
    this.registeredLoginMethods().filter(
      (type) => !this.temporarilyDisabledLoginMethods.includes(type)
    )
  );
  public addableLoginMethods = computed(() =>
    this.allCredentialTypes.filter(
      (type) =>
        !this.registeredLoginMethods().includes(type) &&
        ![
          CredentialType.None,
          CredentialType.Pin,
          CredentialType.PkiCert,
          ...this.temporarilyDisabledLoginMethods,
        ].includes(type)
    )
  );
  public removableLoginMethods = computed(() =>
    this.registeredLoginMethods().filter(
      (type) =>
        ![
          CredentialType.Password,
          ...this.temporarilyDisabledLoginMethods,
        ].includes(type)
    )
  );

  public set editMode(value: boolean) {
    this.editModeSubject.next(value);
  }

  private set profilePic(profilePic: string | null) {
    this.displayedProfilePicSubject.next(
      profilePic ? profilePic : placeholderUserPicPath
    );
  }

  get currentProfilePic() {
    return this.displayedProfilePicSubject.value;
  }

  private set userInfo(userInfo: UserInfo) {
    this.userInfoSubject.next(userInfo);
  }

  public get userInfo(): UserInfo | null {
    return this.userInfoSubject.value;
  }

  private set userPreferences(userPreferences: UserPreferences) {
    this.userPreferencesSubject.next(userPreferences);
  }

  public get userPreferences(): UserPreferences | null {
    return this.userPreferencesSubject.value;
  }

  constructor(
    private http: HttpClient,
    private globalLoader: GlobalLoaderService,
    private authService: BingliAuthService,
    private snackbarService: SnackbarService,
    private clientService: ClientService,
    private languageService: LanguageService,
    private translateService: TranslateService
  ) {}

  public initializeService() {
    this.fetchUserProfile();
    this.fetchRegisteredCredentialTypes();
  }

  private fetchUserProfile(): void {
    const url = `${environment.bossApi}/tenant-api/userprofile`;

    this.http
      .get<IApiResponse<UserProfile>>(url)
      .pipe(
        take(1),
        finalize(() => this.useLanguagePreference())
      )
      .subscribe({
        next: (response: IApiResponse<UserProfile>) => {
          const { userInfo, userPreferences, profilePic } = response.data;
          this.userInfo = userInfo;
          this.userPreferences = userPreferences;
          this.profilePic = profilePic;
        },
        error: (error: any) => {
          console.error('Failed to fetch user profile', error);
        },
      });
  }

  public postUserInfo(updatedUserInfo: UserInfo): void {
    const url = `${environment.bossApi}/tenant-api/userprofile/save-user-info`;
    const body = new ApiRequest<UserInfo>(updatedUserInfo);
    const loaderKey = 'saveUserInfo';
    this.globalLoader.startLoading(loaderKey);

    this.http
      .post<IApiResponse<UserInfo>>(url, body)
      .pipe(
        take(1),
        finalize(() => {
          this.globalLoader.stopLoading(loaderKey);
        })
      )
      .subscribe({
        next: () => {
          this.userInfo = updatedUserInfo;
          this.snackbarService.success('changes_saved');
        },
        error: () => {
          this.snackbarService.showGenericErrorMessage();
        },
      });
  }

  public postUserPreferences(updatedUserPreferences: UserPreferences): void {
    const url = `${environment.bossApi}/tenant-api/userprofile/save-user-preferences`;
    const body = new ApiRequest<UserPreferences>(updatedUserPreferences);
    const loaderKey = 'saveUserPreferences';
    this.globalLoader.startLoading(loaderKey);

    this.http
      .post<IApiResponse<UserInfo>>(url, body)
      .pipe(
        take(1),
        finalize(() => {
          this.globalLoader.stopLoading(loaderKey);
        })
      )
      .subscribe({
        next: () => {
          this.userPreferences = updatedUserPreferences;
          if (
            updatedUserPreferences.language !==
            this.translateService.currentLang
          )
            this.languageService.changeAppLanguage(
              updatedUserPreferences.language
            );
          this.snackbarService.languageChangeSuccess('changes_saved');
          this.authService.refreshToken();
        },
        error: () => {
          this.snackbarService.showGenericErrorMessage();
        },
      });
  }

  public updateLanguagePreference({ language }: { language: string }) {
    const needsUpdate = this.userPreferencesSubject.value.language !== language;
    if (!needsUpdate || !language) return;

    this.languageService.changeAppLanguage(language);
    const updatedUserPreferences = {
      ...this.userPreferencesSubject.value,
      language,
    };

    this.postUserPreferences(updatedUserPreferences);
  }

  public postUserPic({ formData }: { formData: FormData }) {
    const { userId } = this.authService.getAuthInfo();

    const url = `${environment.bossApi}/tenant-api/user/${userId}/profilepicture`;

    this.http
      .post<IApiResponse<User>>(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .pipe(
        take(1),
        map((response) => response.data)
      )
      .subscribe({
        next: (data) => {
          const updatedProfilePic =
            data && data.profilePic ? data.profilePic : null;
          this.displayedProfilePicSubject.next(
            updatedProfilePic ? updatedProfilePic : placeholderUserPicPath
          );
          this.profilePicChangeSuccessSubject.next();
        },
      });
  }

  public deleteUserPic() {
    const { userId } = this.authService.getAuthInfo();
    const url = `${environment.bossApi}/tenant-api/user/${userId}/profilepicture`;

    this.http
      .delete(url)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.displayedProfilePicSubject.next(placeholderUserPicPath);
          this.profilePicChangeSuccessSubject.next();
        },
      });
  }

  public toggleEditMode() {
    this.editMode = !this.editModeSubject.value;
  }

  public fetchRegisteredCredentialTypes(): void {
    this.authService.getCurrentRegisteredCredentials().subscribe({
      next: (response) => this.registeredLoginMethods.set(response.listData),
      error: (err) => console.error(err),
    });
  }

  private useLanguagePreference() {
    const currentLang = this.translateService.currentLang;
    const preferredLang = this.userPreferencesSubject.value?.language;
    const tenantLang = this.clientService.tenantLang;
    const localStorageLang = localStorage.getItem(localStorageLanguageKey);

    const availableLanguages = [
      preferredLang,
      localStorageLang,
      tenantLang,
      fallbackLanguage,
    ];

    const langToUse = availableLanguages.find(
      (lang) =>
        lang && practitionerLanguagesUppercased.includes(lang.toUpperCase())
    );

    if (langToUse && langToUse !== currentLang) {
      this.languageService.changeAppLanguage(langToUse);
    }
  }
}
