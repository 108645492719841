import { getDateFormatString } from '@shared/components/date-picker/helper';
import { EMPTY_STRING } from '@shared/components/inputs/phone-input/utils/constants';
import { INTL_PHONE_MASKS } from '@shared/components/inputs/phone-input/utils/intl-phone-masks';
import {
  noDataPlaceholder,
  localeUS,
  defaultLocale,
} from '@shared/data/constants';
import { CountryIsoCode } from '@shared/data/country-iso-code';
import { Country } from '@shared/data/models/client.model';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { SurveyInfoType } from 'src/app/graphql/surveys/surveys.generated';
import {
  PatientData,
  PatientPhone,
} from 'src/app/modules/dashboard/data/dashboard.model';
import { IPatientInfo } from 'src/app/modules/summary/summary.interface';

export const getValueText = (v: number): string => {
  if (v === 0) {
    return '-';
  }

  const fracTexts = [
    { value: 0.25, text: '¼' },
    { value: 0.5, text: '½' },
    { value: 0.75, text: '¾' },
  ];

  const integer = Math.floor(v);
  const frac = v - integer;

  const fracText = fracTexts.find((ft) => ft.value === frac);
  return fracText
    ? integer < 1.0
      ? fracText.text
      : `${integer} ${fracText.text}`
    : v.toString();
};

export const filterPrefix = (
  fullString: string | undefined,
  prefix: string | undefined
): string => {
  if (!fullString || !prefix) return '';
  if (fullString.startsWith(prefix)) return fullString.substring(prefix.length);
  return fullString;
};

export const parseCountryFromPhoneNumberString = (
  phoneNumber: string,
  countryCodeToCountryIdLookup: Map<string, string>,
  countryIdToCountryLookup: Map<string, Country>
): Country | undefined => {
  if (phoneNumber) {
    for (const countryCode of Object.keys(INTL_PHONE_MASKS)) {
      const phoneMask = INTL_PHONE_MASKS[countryCode as CountryIsoCode];

      if (phoneNumberMatchesMask(phoneNumber, phoneMask)) {
        const countryPrefixWithoutPlus = filterDigits(phoneMask);
        const phoneCountryId = countryCodeToCountryIdLookup.get(
          `+${countryPrefixWithoutPlus}`
        );

        return countryIdToCountryLookup.get(phoneCountryId || EMPTY_STRING);
      }
    }
  }
  return undefined;
};

const phoneNumberMatchesMask = (
  phoneNumber: string,
  phoneMask: string
): boolean => {
  if (!phoneNumber || !phoneMask) {
    return false;
  }

  const phoneNumberDigits = filterDigits(phoneNumber);
  const phoneMaskDigits = filterDigits(phoneMask);

  if (!phoneNumberDigits.startsWith(phoneMaskDigits)) {
    return false;
  }

  const remainingDigitsCount = phoneNumberDigits.slice(
    phoneMaskDigits.length
  ).length;
  const hashesCount = countHashes(phoneMask);

  return remainingDigitsCount === hashesCount;
};

const countHashes = (str: string): number => (str.match(/#/g) || []).length;
export const filterDigits = (str: string) => str.replace(/\D/g, '');

export const isType = <T>(value: unknown): value is T => {
  return typeof value === 'number';
};

export const getStringValue = (dateValue: string, language = 'en'): string => {
  const date = new Date(dateValue);
  return date.toLocaleDateString(language, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  });
};

export const formatPatientPhone = (
  value: PatientPhone,
  countryIdToCountryLookup: Map<string, Country>
) => {
  const hasPhoneNumber = Boolean(value);

  if (hasPhoneNumber) {
    const phoneCountry =
      countryIdToCountryLookup.get(value?.phoneCountryId || EMPTY_STRING) ||
      null;

    const phoneNumber = filterDigits(value?.phoneNumber) || EMPTY_STRING;

    return `${phoneCountry?.countryCode || EMPTY_STRING}${phoneNumber}`;
  }

  return EMPTY_STRING;
};

/** Formats patient name with an option for surname first. */
export const formatPatientName = (
  value: SurveyInfoType | PatientData | IPatientInfo | null,
  surnameFirst: boolean = false
) => {
  if (!value) return EMPTY_STRING;

  return value.firstName && value.lastName
    ? surnameFirst
      ? `${value.lastName} ${value.firstName}`
      : `${value.firstName} ${value.lastName}`
    : value.lastName
    ? `${value.lastName}`
    : value.firstName
    ? `${value.firstName}`
    : noDataPlaceholder;
};

/** Converts ISO string to date format: MM/DD/YYYY for US locale, and DD/MM/YYYY for other locales. */
export const getFormattedDate = (
  value: string | null,
  useUSFormat: boolean
) => {
  if (!value) return EMPTY_STRING;
  const locale = useUSFormat ? localeUS : defaultLocale;
  const dateFormat = getDateFormatString(locale);
  return moment(value).utc(true).format(dateFormat);
};

/** Manages a subscription by key, unsubscribing any previous subscription with the same key. */
export const manageSubscriptionByKey = (
  key: string,
  subscription: Subscription
) => {
  pendingSubscriptions[key]?.unsubscribe();
  subscription.add(() => delete pendingSubscriptions[key]);
  pendingSubscriptions[key] = subscription;
};
const pendingSubscriptions: { [key: string]: Subscription } = {};

/** Manages a timeout by key, clearing any previous timeout with the same key. */
export const manageTimeoutByKey = (
  key: string,
  method: () => void,
  duration: number
) => {
  if (timeouts[key]) {
    clearTimeout(timeouts[key]);
  }
  timeouts[key] = setTimeout(() => {
    method();
    delete timeouts[key];
  }, duration);
};
const timeouts: { [key: string]: ReturnType<typeof setTimeout> } = {};
