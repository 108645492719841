import { UserPreferences } from './account.model';
import { defaultLanguage } from 'src/app/shared/data/constants';

export const maximumAllowedUserAge = 125;

export const placeholderUserPicPath = 'assets/icons/user.svg';

export const noValuePlaceholder = '-';

export const defaultUserInfo = {
  firstName: '',
  middleName: '',
  lastName: '',
  gender: 0,
  dateOfBirth: '',
  phone: '',
  riziv: '',
  email: '',
};

export const defaultUserPreferences: UserPreferences = {
  showMedicalTerm: true,
  showPatientInfoInClipboard: false,
  language: defaultLanguage,
};
