import { CountryIsoCode } from 'src/app/shared/data/country-iso-code';

export const INTL_PHONE_MASKS = {
  [CountryIsoCode.AD]: `+376###-###`,
  [CountryIsoCode.AE]: `+971-##-###-####`,
  [CountryIsoCode.AF]: `+93##-###-####`,
  [CountryIsoCode.AG]: `+1(268) ###-####`,
  [CountryIsoCode.AI]: `+1(264) ###-####`,
  [CountryIsoCode.AL]: `+355(###) ###-###`,
  [CountryIsoCode.AM]: `+374##-###-###`,
  [CountryIsoCode.AO]: `+244(###) ###-###`,
  [CountryIsoCode.AR]: `+54(###) ####-####`,
  [CountryIsoCode.AT]: `+43(###) ###-####`,
  [CountryIsoCode.AU]: `+61#-####-####`,
  [CountryIsoCode.AW]: `+297###-####`,
  [CountryIsoCode.AZ]: `+994##-###-##-##`,
  [CountryIsoCode.BA]: `+387 ###-####-##`,
  [CountryIsoCode.BB]: `+1(246) ###-####`,
  [CountryIsoCode.BD]: `+880##-###-###`,
  [CountryIsoCode.BE]: `+32(###) ###-###`,
  [CountryIsoCode.BF]: `+226##-##-####`,
  [CountryIsoCode.BG]: `+359(###) ###-###`,
  [CountryIsoCode.BH]: `+973####-####`,
  [CountryIsoCode.BI]: `+257##-##-####`,
  [CountryIsoCode.BJ]: `+229##-##-####`,
  [CountryIsoCode.BL]: `+590 ## ## ## ## ##`,
  [CountryIsoCode.BM]: `+1(441) ###-####`,
  [CountryIsoCode.BN]: `+673###-####`,
  [CountryIsoCode.BO]: `+591#-###-####`,
  [CountryIsoCode.BQ]: `+599-###-####`,
  [CountryIsoCode.BR]: `+55(##) ####-####`,
  [CountryIsoCode.BS]: `+1(242) ###-####`,
  [CountryIsoCode.BT]: `+975#-###-###`,
  [CountryIsoCode.BW]: `+267##-###-###`,
  [CountryIsoCode.BY]: `+375(##) ###-##-##`,
  [CountryIsoCode.BZ]: `+501###-####`,
  [CountryIsoCode.CA]: `+1(###) ###-####`,
  [CountryIsoCode.CD]: `+243(###) ###-###`,
  [CountryIsoCode.CF]: `+236-##-##-####`,
  [CountryIsoCode.CG]: `+242##-###-####`,
  [CountryIsoCode.CH]: `+41##-###-####`,
  [CountryIsoCode.CI]: `+225##-##-###-###`,
  [CountryIsoCode.CL]: `+56#-####-####`,
  [CountryIsoCode.CM]: `+237#####-####`,
  [CountryIsoCode.CN]: `+86(###) ####-####`,
  [CountryIsoCode.CO]: `+57(###) ###-####`,
  [CountryIsoCode.CR]: `+506####-####`,
  [CountryIsoCode.CU]: `+53#-###-####`,
  [CountryIsoCode.CV]: `+238(###) ##-##`,
  [CountryIsoCode.CW]: `+5999-###-####`,
  [CountryIsoCode.CY]: `+357##-###-###`,
  [CountryIsoCode.CZ]: `+420(###) ###-###`,
  [CountryIsoCode.DE]: `+49(###) ###-###-##`,
  [CountryIsoCode.DJ]: `+253##-##-##-##`,
  [CountryIsoCode.DK]: `+45##-##-##-##`,
  [CountryIsoCode.DM]: `+1(767) ###-####`,
  [CountryIsoCode.DO]: `+1(###) ###-####`,
  [CountryIsoCode.DZ]: `+213##-###-####`,
  [CountryIsoCode.EC]: `+593##-###-####`,
  [CountryIsoCode.EE]: `+372####-####`,
  [CountryIsoCode.EG]: `+20(###) ###-####`,
  [CountryIsoCode.ER]: `+291#-###-###`,
  [CountryIsoCode.ES]: `+34(###) ###-###`,
  [CountryIsoCode.ET]: `+251##-###-####`,
  [CountryIsoCode.FI]: `+358(###) ###-##-##`,
  [CountryIsoCode.FJ]: `+679##-#####`,
  [CountryIsoCode.FK]: `+500#####`,
  [CountryIsoCode.FM]: `+691###-####`,
  [CountryIsoCode.FR]: `+33 ## ## ## ## ##`,
  [CountryIsoCode.GA]: `+241##-##-##-##`,
  [CountryIsoCode.GB]: `+44##-####-####`,
  [CountryIsoCode.GD]: `+1(473) ###-####`,
  [CountryIsoCode.GE]: `+995(###) ###-###`,
  [CountryIsoCode.GF]: `+594 ## ## ## ## ##`,
  [CountryIsoCode.GH]: `+233(###) ###-###`,
  [CountryIsoCode.GI]: `+350###-#####`,
  [CountryIsoCode.GL]: `+299##-##-##`,
  [CountryIsoCode.GM]: `+220(###) ##-##`,
  [CountryIsoCode.GN]: `+224##-###-###`,
  [CountryIsoCode.GP]: `+590 ## ## ## ## ##`,
  [CountryIsoCode.GQ]: `+240##-###-####`,
  [CountryIsoCode.GR]: `+30(###) ###-####`,
  [CountryIsoCode.GT]: `+502#-###-####`,
  [CountryIsoCode.GW]: `+245#-######`,
  [CountryIsoCode.GY]: `+592###-####`,
  [CountryIsoCode.HK]: `+852####-####`,
  [CountryIsoCode.HN]: `+504####-####`,
  [CountryIsoCode.HR]: `+385##-###-####`,
  [CountryIsoCode.HT]: `+509##-##-####`,
  [CountryIsoCode.HU]: `+36(###) ###-###`,
  [CountryIsoCode.ID]: `+62(###) ###-##-###`,
  [CountryIsoCode.IE]: `+353(###) ###-###`,
  [CountryIsoCode.IL]: `+972##-###-####`,
  [CountryIsoCode.IN]: `+91(####) ###-###`,
  [CountryIsoCode.IQ]: `+964(###) ###-####`,
  [CountryIsoCode.IR]: `+98(###) ###-####`,
  [CountryIsoCode.IS]: `+354###-####`,
  [CountryIsoCode.IT]: `+39(###) ####-###`,
  [CountryIsoCode.JM]: `+1(876) ###-####`,
  [CountryIsoCode.JO]: `+962#-####-####`,
  [CountryIsoCode.JP]: `+81-##-####-####`,
  [CountryIsoCode.KE]: `+254###-######`,
  [CountryIsoCode.KG]: `+996(###) ###-###`,
  [CountryIsoCode.KH]: `+855##-###-###`,
  [CountryIsoCode.KM]: `+269##-#####`,
  [CountryIsoCode.KN]: `+1(869) ###-####`,
  [CountryIsoCode.KP]: `+850####-#############`,
  [CountryIsoCode.KR]: `+82##-###-####`,
  [CountryIsoCode.KW]: `+965####-####`,
  [CountryIsoCode.KY]: `+1(345) ###-####`,
  [CountryIsoCode.KZ]: `+7(###) ###-##-##`,
  [CountryIsoCode.LA]: `+856##-##-###-###`,
  [CountryIsoCode.LB]: `+961##-###-###`,
  [CountryIsoCode.LC]: `+1(758) ###-####`,
  [CountryIsoCode.LI]: `+423(###) ###-####`,
  [CountryIsoCode.LK]: `+94##-###-####`,
  [CountryIsoCode.LR]: `+231##-###-###`,
  [CountryIsoCode.LS]: `+266#-###-####`,
  [CountryIsoCode.LT]: `+370(###) ##-###`,
  [CountryIsoCode.LU]: `+352(###) ###-###`,
  [CountryIsoCode.LV]: `+371##-###-###`,
  [CountryIsoCode.LY]: `+218##-###-####`,
  [CountryIsoCode.MA]: `+212##-####-###`,
  [CountryIsoCode.MC]: `+377###-###-###`,
  [CountryIsoCode.MD]: `+373####-####`,
  [CountryIsoCode.ME]: `+382##-###-###`,
  [CountryIsoCode.MF]: `+590 ## ## ## ## ##`,
  [CountryIsoCode.MG]: `+261##-##-#####`,
  [CountryIsoCode.MK]: `+389##-###-###`,
  [CountryIsoCode.ML]: `+223##-##-####`,
  [CountryIsoCode.MM]: `+95##-###-###`,
  [CountryIsoCode.MN]: `+976##-##-####`,
  [CountryIsoCode.MO]: `+853####-####`,
  [CountryIsoCode.MQ]: `+596 ## ## ## ## ##`,
  [CountryIsoCode.MR]: `+222##-##-####`,
  [CountryIsoCode.MS]: `+1(664) ###-####`,
  [CountryIsoCode.MT]: `+356####-####`,
  [CountryIsoCode.MU]: `+230####-####`,
  [CountryIsoCode.MV]: `+960###-####`,
  [CountryIsoCode.MW]: `+265#-####-####`,
  [CountryIsoCode.MX]: `+52(###) ###-####`,
  [CountryIsoCode.MY]: `+60(###) ###-###`,
  [CountryIsoCode.MZ]: `+258##-###-###`,
  [CountryIsoCode.NA]: `+264##-###-####`,
  [CountryIsoCode.NC]: `+687 ### ###`,
  [CountryIsoCode.NE]: `+227##-##-####`,
  [CountryIsoCode.NG]: `+234(###) ###-####`,
  [CountryIsoCode.NI]: `+505####-####`,
  [CountryIsoCode.NL]: `+31##-###-####`,
  [CountryIsoCode.NO]: `+47(###) ##-###`,
  [CountryIsoCode.NP]: `+977##-###-###`,
  [CountryIsoCode.NZ]: `+64(###) ###-####`,
  [CountryIsoCode.OM]: `+968##-###-###`,
  [CountryIsoCode.PA]: `+507###-####`,
  [CountryIsoCode.PE]: `+51(###) ###-###`,
  [CountryIsoCode.PF]: `+689 ## ## ## ##`,
  [CountryIsoCode.PG]: `+675(###) ##-###`,
  [CountryIsoCode.PH]: `+63(###) ###-####`,
  [CountryIsoCode.PK]: `+92(###) ###-####`,
  [CountryIsoCode.PL]: `+48(###) ###-###`,
  [CountryIsoCode.PT]: `+351##-###-####`,
  [CountryIsoCode.PW]: `+680###-####`,
  [CountryIsoCode.PY]: `+595(###) ###-###`,
  [CountryIsoCode.QA]: `+974####-####`,
  [CountryIsoCode.RE]: `+262 ## ## ## ## ##`,
  [CountryIsoCode.RO]: `+40##-###-####`,
  [CountryIsoCode.RS]: `+381##-###-####`,
  [CountryIsoCode.RU]: `+7### ###-##-##`,
  [CountryIsoCode.RW]: `+250(###) ###-###`,
  [CountryIsoCode.SA]: `+966#-####-####`,
  [CountryIsoCode.SB]: `+677###-####`,
  [CountryIsoCode.SC]: `+248#-###-###`,
  [CountryIsoCode.SD]: `+249##-###-####`,
  [CountryIsoCode.SE]: `+46##-###-####`,
  [CountryIsoCode.SG]: `+65####-####`,
  [CountryIsoCode.SH]: `+290####`,
  [CountryIsoCode.SI]: `+386##-###-###`,
  [CountryIsoCode.SK]: `+421(###) ###-###`,
  [CountryIsoCode.SL]: `+232##-######`,
  [CountryIsoCode.SM]: `+378####-######`,
  [CountryIsoCode.SN]: `+221##-###-####`,
  [CountryIsoCode.SO]: `+252##-###-###`,
  [CountryIsoCode.SR]: `+597###-####`,
  [CountryIsoCode.ST]: `+239##-#####`,
  [CountryIsoCode.SV]: `+503##-##-####`,
  [CountryIsoCode.SX]: `+1(721) ###-####`,
  [CountryIsoCode.SY]: `+963##-####-###`,
  [CountryIsoCode.SZ]: `+268##-##-####`,
  [CountryIsoCode.TC]: `+1(649) ###-####`,
  [CountryIsoCode.TD]: `+235##-##-##-##`,
  [CountryIsoCode.TG]: `+228##-###-###`,
  [CountryIsoCode.TH]: `+66##-###-####`,
  [CountryIsoCode.TJ]: `+992##-###-####`,
  [CountryIsoCode.TL]: `+670###-#####`,
  [CountryIsoCode.TM]: `+993#-###-####`,
  [CountryIsoCode.TN]: `+216##-###-###`,
  [CountryIsoCode.TO]: `+676#####`,
  [CountryIsoCode.TR]: `+90(###) ###-####`,
  [CountryIsoCode.TT]: `+1(868) ###-####`,
  [CountryIsoCode.TW]: `+886#-####-####`,
  [CountryIsoCode.TZ]: `+255##-###-####`,
  [CountryIsoCode.UA]: `+380(##) ###-##-##`,
  [CountryIsoCode.UG]: `+256(###) ###-###`,
  [CountryIsoCode.US]: `+1(###) ###-####`,
  [CountryIsoCode.UY]: `+598#-###-##-##`,
  [CountryIsoCode.UZ]: `+998##-###-####`,
  [CountryIsoCode.VC]: `+1(784) ###-####`,
  [CountryIsoCode.VE]: `+58(###) ###-####`,
  [CountryIsoCode.VG]: `+1(284)###-####`,
  [CountryIsoCode.VN]: `+84(###) ####-###`,
  [CountryIsoCode.VU]: `+678##-#####`,
  [CountryIsoCode.WS]: `+685##-####`,
  [CountryIsoCode.XK]: `+383##-###-###`,
  [CountryIsoCode.YE]: `+967###-###-###`,
  [CountryIsoCode.YT]: `+262 ## ## ## ## ##`,
  [CountryIsoCode.ZA]: `+27##-###-####`,
  [CountryIsoCode.ZM]: `+260##-###-####`,
  [CountryIsoCode.ZW]: `+263#-######`,
};
